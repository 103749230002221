import { doc, getDoc, updateDoc } from "firebase/firestore";

import { firebaseAuth, database } from "../config/firebaseConfig";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { authAtom } from "../atoms/index";
import { VendorInfo } from "../types";
import useFirebaseAuth from "../hooks/useFirebaseAuth";
export { useAuthActions };

function useAuthActions() {
  const setAuthInfo = useSetRecoilState(authAtom);

  const firebaseAuth = useFirebaseAuth();

  return {
    get_auth_info,
    update_auth_info,
    logout,
  };
  async function get_auth_info() {}

  async function update_auth_info() {}

  async function logout() {
    setAuthInfo(false);
    firebaseAuth.clear();
  }
}
