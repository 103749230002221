import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Container, Form, Button, Row, Col } from "react-bootstrap";

import { signInWithEmailAndPassword } from "firebase/auth";

import Image from "react-bootstrap/Image";
import { useFormik } from "formik";

import { firebaseAuth, database } from "../config/firebaseConfig";
import { authAtom, vendorInfoAtom } from "../atoms/index";
import { useRecoilState, useSetRecoilState } from "recoil";
import { VendorInfo } from "../types";

import { doc, getDoc } from "firebase/firestore";
import { useVendorActions } from "../actions/vendor.actions";

interface LoginFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const [error, setError] = useState(null);
  const [isLogin, setIsLogin] = useRecoilState(authAtom);
  const vendorActions = useVendorActions();

  let navigate = useNavigate();

  useEffect(() => {
    if (isLogin && firebaseAuth.currentUser) {
      navigate("/dashboard", { replace: true });
    }
  }, []);

  const loginFormik = useFormik<LoginFormValues>({
    initialValues: { email: "", password: "" },

    onSubmit: async (formValues) => {
      setError(null);

      try {
        await signInWithEmailAndPassword(
          firebaseAuth,
          formValues.email,
          formValues.password
        );

        vendorActions.get_vendor_info();
        setIsLogin(true);
        navigate("/dashboard", { replace: true });
      } catch (error) {
        // setError(true);
        alert("Wrong user name or password");
        console.log("ERROR" + error);
      }
    },
  });
  return (
    <Container
      fluid="md"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Row>
        <Col
          xs={{ span: 12 }}
          md={{ span: 4, offset: 4 }}
          lg={{ span: 4, offset: 4 }}
        >
          <Form onSubmit={loginFormik.handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Image src="./assets/logo-bg.png" fluid={true} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter email"
                onChange={loginFormik.handleChange}
                value={loginFormik.values.email}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Password"
                onChange={loginFormik.handleChange}
                value={loginFormik.values.password}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Remember me" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
