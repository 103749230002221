import React, { useEffect } from "react";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import { DealInfo } from "../types";
import { useRecoilState, useRecoilValue } from "recoil";
import DealModal from "./deal.modal";
import { dealAtom, dealInfoModalAtom, selectedDealAtom } from "../atoms/index";
import { useDealActions } from "../actions/deal.actions";

const DealContainer = () => {
  const deals = useRecoilValue<DealInfo[]>(dealAtom);
  const [selectedDeal, setSelectedDeal] = useRecoilState<DealInfo | null>(
    selectedDealAtom
  );
  const handleDealModalClose = () => setShowDealModal(false);

  const [showDealModal, setShowDealModal] = useRecoilState<boolean>(
    dealInfoModalAtom
  );

  const dealActions = useDealActions();

  const handleShowDeal = (deal: DealInfo | null) => {
    setSelectedDeal(deal);
    setShowDealModal(true);
  };

  const handleDeleteDeal = async () => {
    dealActions.delete_deal();
    setShowDealModal(false);
  };

  useEffect(() => {
    dealActions.get_deals_info();
  }, [showDealModal]);

  return (
    <>
      {showDealModal && (
        <DealModal
          selectedDeal={selectedDeal}
          handleDealModalClose={handleDealModalClose}
          handleDeleteDeal={handleDeleteDeal}
        />
      )}
      <Container className="mt-2">
        <Row className="justify-content-md-center">
          <Col xs={12} sm={6} md={4} lg={3} className="mt-4">
            <Card
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <Card.Body
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() => handleShowDeal(null)}
              >
                <Card.Text>+</Card.Text>
                <Card.Text>Create a Deal</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {deals.map((deal) => (
            <Col
              className="mt-4"
              key={deal.dealId}
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <Card
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  // alignItems:"center",
                }}
                className="shadow-lg rounded"
                onClick={() => handleShowDeal(deal)}
              >
                <Card.Img
                  style={{
                    height: "150px",
                    width: "100%",
                  }}
                  variant="top"
                  src={deal.imageUrl || "./assets/logo-bg.png"}
                />
                <Card.Body className="text-left">
                  <Card.Title className="d-flex justify-content-between">
                    {deal.name}
                    {deal.isActive ? (
                      <Badge bg="success">Active</Badge>
                    ) : (
                      <Badge bg="danger">Inactive</Badge>
                    )}
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default DealContainer;
