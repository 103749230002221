import { doc, getDoc, updateDoc } from "firebase/firestore";

import { firebaseAuth, database } from "../config/firebaseConfig";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { vendorInfoAtom } from "../atoms/index";
import { VendorInfo } from "../types";

export { useVendorActions };

function useVendorActions() {
  const setVendorInfo = useSetRecoilState(vendorInfoAtom);

  return {
    get_vendor_info,
    update_vendor_info,
  };
  async function get_vendor_info() {
    const docRef = doc(database, "Vendors", firebaseAuth.currentUser!.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const vendorInfo = docSnap.data();

      debugger;

      setVendorInfo((prevState) => ({
        ...prevState,
        ...vendorInfo,
      }));

      // setVendorInfo((names) => {...names, vendorInfo});
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  async function update_vendor_info(vendorInfo: VendorInfo) {
    const updatedInfo = doc(database, "Vendors", vendorInfo.vendorId);
    debugger;
    try {
      await updateDoc(updatedInfo, vendorInfo);
      setVendorInfo((prevState) => ({
        ...prevState,
        ...vendorInfo,
      }));
    } catch (e) {
      alert("Error updating");
    }
  }
}
