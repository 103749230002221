import React, { useEffect } from "react";
import Header from "../component/header";
import { useNavigate } from "react-router-dom";

import { firebaseAuth } from "../config/firebaseConfig";
import { useRecoilValue } from "recoil";
import VendorInfoModal from "../component/vendorinfo.modal";
import { authAtom, vendorInfoModalAtom } from "../atoms/index";
import { useDealActions } from "../actions/deal.actions";

import DealContainer from "../component/deal.container";

const Dashboard = () => {
  const showVendorInfoModal = useRecoilValue(vendorInfoModalAtom);
  const isLogin = useRecoilValue(authAtom);
  let navigate = useNavigate();
  const dealActions = useDealActions();

  useEffect(() => {
    if (!firebaseAuth.currentUser || !isLogin) {
      navigate("/login", { replace: true });
      return;
    }
    dealActions.get_deals_info();
  }, [isLogin]);

  return (
    <div>
      {showVendorInfoModal && <VendorInfoModal />}

      <Header />
      <DealContainer />
    </div>
  );
};

export default Dashboard;
