import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import GenerateQr from "./pages/generateQr";
import Landing from "./pages/landing";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="login" element={<Login />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="generateQr" element={<GenerateQr />} />

      {/* Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for. */}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default Router;
