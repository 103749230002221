import React from "react";
import { Container, Badge, Image, Card } from "react-bootstrap";

import { QrPDFInfo } from "../types/";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";

const PdfPage = ({ info }: { info: QrPDFInfo }) => {
  const { name, offerType, offerValue, qrCode } = info;

  console.log("QRq+" + JSON.stringify(qrCode));

  const printRef = React.useRef(HTMLElement);

  const handleDownloadPdf = async () => {
    const element = document.getElementById("divToPrint")!;
    const data = await htmlToImage.toJpeg(element);

    // const data = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
    });
    pdf.addImage(data, 0, 0, 210, 297);
    pdf.output("dataurlnewwindow");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          id="divToPrint"
          style={{
            height: "297mm",
            width: "210mm",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignContent: "center",
            backgroundColor: "#339AF0",
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          <div
            style={{
              height: "100mm",
              display: "flex",
              justifyContent: "flex-start",
              borderWidth: 1,
              color: "#fff",
              // borderColor: "red",
              // backgroundColor: "red",

              alignItems: "center",
            }}
          >
            <Image
              style={{ height: "35mm", width: "75mm", marginLeft: "5mm" }}
              src="../assets/logo-bg.png"
              alt="ISC LOGO"
            />
            <div
              style={{
                justifyContent: "flex-start",
                // alignItems: "center",
                height: "25mm",
                display: "flex",
                marginLeft: "20mm",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontSize: "7mm",
                  fontFamily: "Helvetica",
                  fontWeight: 400,
                }}
              >
                BEST DEALS
              </span>
              <span
                style={{
                  fontSize: "7mm",
                  color: "#D94B27",
                  fontFamily: "Helvetica",
                  fontWeight: 600,
                }}
              >
                FOR THE STUDENTS
              </span>
            </div>
          </div>

          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",

              paddingTop: "40mm",
              paddingBottom: "40mm",
              height: "150mm",
              borderRadius: "20mm",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderWidth: 1,
                borderColor: "red",
              }}
            >
              <span
                style={{
                  fontSize: "7mm",
                  color: "#339AF0",
                  // marginBottom: 10,
                }}
              >
                Open the app and scan QR code to register the deal
              </span>

              <span
                style={{
                  fontSize: "15mm",
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#339AF0",
                }}
              >
                {name}
              </span>
            </div>
            <div>
              <Image
                style={{ height: "80mm", width: "80mm", padding: "5mm" }}
                src={`https://api.qrserver.com/v1/create-qr-code/?data=${qrCode}&size=250x250`}
                alt="QRCode"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderWidth: 1,
                borderColor: "red",
              }}
            >
              <span
                style={{
                  fontSize: "7mm",
                  fontWeight: 600,
                  color: "#339AF0",
                }}
              >
                {offerType === "PERCENT" ? (
                  <>{offerValue}% off</>
                ) : (
                  <>${offerValue} off</>
                )}
              </span>
              <span
                style={{
                  fontSize: "5mm",
                }}
              >
                International Student Club
              </span>
            </div>
          </Card>
          <div
            style={{
              marginTop: "20mm",
              height: "100mm",
            }}
          >
            <div>
              <span
                style={{
                  fontSize: "5mm",
                  fontWeight: 600,
                }}
              >
                DOWNLOAD NOW
              </span>
            </div>
            <Image
              style={{ height: "25mm", width: "70mm" }}
              src="../assets/Available_on_the_App_Store_logo.png"
              alt="App Store"
            />
          </div>
        </div>
      </div>
      <button onClick={handleDownloadPdf}>Print</button>
    </>
  );
};

export default PdfPage;
