import { atom } from "recoil";
import { VendorInfo } from "../types";
import localStorageEffect from "./util";

export const vendorInfoAtom = atom<VendorInfo>({
  key: "vendorInfoState",
  default: {
    vendorId: "",
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    imageUrl: "",
    description: "",
    location: null,
  },
  effects: [localStorageEffect("vendor_info")],
});

export const vendorInfoModalAtom = atom<any>({
  key: "vendorInfoModalState",
  default: false,
});
