import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Popover, Card } from "react-bootstrap";

import Overlay from "react-bootstrap/Overlay";
import Image from "react-bootstrap/Image";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, vendorInfoModalAtom, vendorInfoAtom } from "../atoms/index";
import { useAuthActions } from "../actions/auth.actions";

const Header = () => {
  let navigation = useNavigate();
  const vendorInfo = useRecoilValue(vendorInfoAtom);
  const authActions = useAuthActions();

  const [showVendorInfoModal, setShowVendorInfoModal] = useRecoilState(
    vendorInfoModalAtom
  );

  const logout = () => {
    authActions.logout();

    // navigation("/login", { replace: true });
  };

  const openEditProfile = () => {
    setShow(false);
    setShowVendorInfoModal(true);
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  // const addTenCelsius = () => setTempC(tempC + 10);
  // const addTenFahrenheit = () => setTempF(tempF + 10);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Card style={{ border: 0 }}>
          <Card.Text style={{ cursor: "grab" }} onClick={openEditProfile}>
            Edit Profile
          </Card.Text>
          <Card.Text style={{ color: "red", cursor: "grab" }} onClick={logout}>
            Logout
          </Card.Text>
        </Card>
      </Popover.Body>
    </Popover>
  );

  return (
    <Navbar bg="light">
      <Container>
        <Navbar.Brand href="#home">International Student Club</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <div ref={ref}>
            {/* <Button onClick={handleClick}>Holy guacamole!</Button> */}
            <Image
              onClick={handleClick}
              src={vendorInfo.imageUrl || "./assets/logo-bg.png"}
              width="20"
              height="20"
            />
            <Overlay
              show={show}
              target={target}
              placement="bottom"
              container={ref}
              containerPadding={20}
            >
              {popover}
            </Overlay>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
