import React from "react";
import logo from "./logo.svg";
import "./App.css";

import Footer from "./component/footer";
import { Routes, Route, Outlet, Link, BrowserRouter } from "react-router-dom";

import Router from "./router";
function App() {
  return (
    <div className="App">
      <div
        style={{
          minHeight: "90vh",
        }}
      >
        <Router />
      </div>
      <Footer />
    </div>
  );
}

export default App;
