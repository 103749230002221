import React from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import PdfPage from "../component/pdf-page";
import { QrPDFInfo } from "../types";
import { qrInfo } from "../atoms";
import { useRecoilValue } from "recoil";

const GenerateQr = () => {
  // const location = useLocation();
  const qrCodeObj = useRecoilValue(qrInfo);
  // alert(JSON.stringify(qrCodeObj1));
  // // const qrCodeObj = JSON.parse(formInfo);
  // const qrCodeObj: QrPDFInfo = {
  //   id: "string",
  //   name: "string",
  //   description: "string",
  //   offerType: "FLAT",
  //   offerValue: 12,
  //   isActive: true,
  //   imageUrl: null,
  //   createdDate: 121212,
  //   modifiedDate: 121212,
  //   vendorId: "sdasdsadasdsad",
  //   qrCode: "string",
  // };
  // alert(JSON.stringify(location));
  return <PdfPage info={qrCodeObj} />;
};

export default GenerateQr;
