import {
  collection,
  doc,
  getDocs,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";

import { firebaseAuth, database } from "../config/firebaseConfig";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { dealAtom, selectedDealAtom } from "../atoms/index";
import { DealInfo } from "../types";

export { useDealActions };

function useDealActions() {
  const setDealInfo = useSetRecoilState(dealAtom);
  const selectedDeal = useRecoilValue(selectedDealAtom);

  const dbInstance = collection(database, "Deals");

  return {
    get_deals_info,
    update_deals_info,
    delete_deal,
  };

  async function get_deals_info() {
    const dealQuery = query(
      dbInstance,
      where("vendorId", "==", firebaseAuth.currentUser!.uid)
    );

    const dealSnapshot = await getDocs(dealQuery);
    // Emptying the deal array to avoid duplicates.
    // setDeals([]);
    setDealInfo([]);

    dealSnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      const dealObj: DealInfo = doc.data() as DealInfo;
      if (dealObj.dealId === "") {
        dealObj.dealId = doc.id;
      }

      console.log("From deal actions" + JSON.stringify(dealObj));

      setDealInfo((prevDeals) => [...prevDeals, dealObj]);
    });
  }

  async function update_deals_info() {}

  async function delete_deal() {
    await deleteDoc(doc(database, "Deals", selectedDeal!.dealId));
  }
}
