import { useState, useEffect } from "react";
// import { useAuth } from "../../context/AuthUserContext";
import {
  onAuthStateChanged,
  User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  UserCredential,
  signOut,
} from "firebase/auth";
import { firebaseAuth } from "../config/firebaseConfig";

const formatAuthUser = (user: User) => ({
  uid: user.uid,
  email: user.email,
});

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const authStateChanged = async (authState: any) => {
    if (!authState) {
      setLoading(false);
      return;
    }

    setLoading(true);

    var formattedUser: any = formatAuthUser(authState);

    setAuthUser(formattedUser);

    setLoading(false);
  };

  useEffect(() => {
    // const auth = getAuth();
    const unsubscribe = onAuthStateChanged(firebaseAuth, authStateChanged);
    return () => unsubscribe();
  }, []);

  const clear = () => {
    setAuthUser(null);
    setLoading(true);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    loading,
    clear,
  };
}
// function signInWithEmailAndPassword(firebaseAuth: Auth, email: string, password: string): UserCredential {
//   throw new Error("Function not implemented.");
// }
