import { atom } from "recoil";
import localStorageEffect from "./util";

const authAtom = atom({
  key: "authAtom",
  // get initial state from local storage to enable user to stay logged in
  default: false,
  effects: [localStorageEffect("auth_info")],
});

export { authAtom };
