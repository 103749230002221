import React from "react";
import {
  DownloadIcon,
  ImageIcon,
  UploadIcon,
  CrossCircledIcon,
} from "@radix-ui/react-icons";
import {
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
  Image,
} from "react-bootstrap";

import { ImageUploadStatus } from "../types";

export default function ImageUploadIcon({
  status,
  ...props
}: React.ComponentPropsWithoutRef<typeof ImageIcon> & {
  status: ImageUploadStatus;
}) {
  console.log("Test" + JSON.stringify(status));
  if (status.accepted) {
    return <Image fluid={true} alt="image upload" src={status.imageUrl} />;
  }

  if (status.rejected) {
    return <CrossCircledIcon {...props} />;
  }

  return <ImageIcon {...props} />;
}
