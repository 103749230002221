import React, { useState, useRef, useEffect } from "react";

import {
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
  Stack,
  Image,
} from "react-bootstrap";

import { useFormik } from "formik";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import {
  firebaseAuth,
  database,
  firebaseStorage,
} from "./../config/firebaseConfig";
import { VendorInfo, ImageUploadStatus } from "../types";

import ImageUploadIcon from "./image-upload-icon";
import { useRecoilState } from "recoil";
import { vendorInfoAtom, vendorInfoModalAtom } from "../atoms/index";
import { useVendorActions } from "../actions/vendor.actions";

const VendorInfoModal = () => {
  const [showVendorInfoModal, setShowVendorInfoModal] = useRecoilState(
    vendorInfoModalAtom
  );

  const vendorActions = useVendorActions();

  // const vendorActions = useVendorActions();

  const [vendorInfo, setVendorInfo] = useRecoilState(vendorInfoAtom);

  const [fileUploadState, setFileUploadState] = useState<ImageUploadStatus>({
    accepted: false,
    rejected: false,
    imageUrl: "",
  });

  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
  const [isUploadingVendorInfo, setIsUploadingVendorInfo] = useState<boolean>(
    false
  );

  const initialVendorInfoFormValues: VendorInfo = vendorInfo;
  debugger;
  const uploadImageFieldRef = useRef<HTMLInputElement>(null);

  const vendorInfoFormik = useFormik<VendorInfo>({
    initialValues: initialVendorInfoFormValues,
    enableReinitialize: true,

    onSubmit: async (vendorInfo: VendorInfo) => {
      console.log("vendorInfo" + JSON.stringify(vendorInfo));

      setIsUploadingVendorInfo(true);

      try {
        // throw new Error("Error");
        debugger;
        // let updatedVendorInfo = {
        //   ...initialVendorInfoFormValues,
        //   ...vendorInfo,
        // };

        vendorActions.update_vendor_info(vendorInfo);

        setShowVendorInfoModal(false);
        // setDealDataCreatedUpdated(true);
        // setOpenDealModal(false);
      } catch (e) {
        alert("Deal could not be update. Please retry.");
      } finally {
        setIsUploadingVendorInfo(true);
      }
    },
  });

  const handleImageUpload = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const image: File = (target.files as FileList)[0];

    // Rejecting image if size is grater than 1 MB.
    if (image.size > 1 * 1024 ** 2) {
      // Greater than 1 mb
      alert("Image size too big");
      setFileUploadState((prevState) => ({ ...prevState, rejected: true }));
      return;
    }

    let imageUrl = URL.createObjectURL(image);

    let extension: string = image.name.split(".").pop() || "";
    let fileName: string = `vendor_${Date.now()}.${extension}`;

    const storageRef = ref(firebaseStorage, fileName);
    setIsUploadingImage(true);
    uploadBytes(storageRef, image).then((snapshot) => {
      console.log("Uploaded a vendor image !!!");
      getDownloadURL(storageRef)
        .then((url) => {
          setIsUploadingImage(false);
          setFileUploadState((prevState) => ({
            ...prevState,
            accepted: true,
            imageUrl: imageUrl,
          }));
          //IMPORTANT  check this
          // vendorInfoFormik.values.imageUrl = url;
          // setFieldValue("imageUrl", url);

          vendorInfoFormik.setFieldValue("imageUrl", url);
        })
        .catch((error) => {
          debugger;
          alert("Upload failed");
          console.log("Upload failed" + error);
        });
    });
  };

  return (
    <Modal
      show={true}
      // onHide={handleVendorInfoModalClose}
      backdrop="static"
      keyboard={false}
      onHide={() => setShowVendorInfoModal(false)}
    >
      <Form onSubmit={vendorInfoFormik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit your Information!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              required
              onChange={vendorInfoFormik.handleChange}
              value={vendorInfoFormik.values.name}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={vendorInfoFormik.handleChange}
              value={vendorInfoFormik.values.description}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="address">
            <Form.Label>Address:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter address"
              required
              onChange={vendorInfoFormik.handleChange}
              value={vendorInfoFormik.values.address}
            />
          </Form.Group>

          {vendorInfoFormik.values.imageUrl === "" ? (
            <Form.Group className="mb-3" controlId="imageUpload">
              <Form.Label>Upload the image :</Form.Label>
              <br />

              {/* For uploading the image */}
              <div onClick={() => uploadImageFieldRef.current!.click()}>
                <Stack direction="horizontal" gap={3}>
                  <ImageUploadIcon
                    // status={fileUploadState}
                    status={fileUploadState}
                    style={{
                      width: 80,
                      height: 80,
                      // color: getIconColor(status, theme),
                    }}
                  />
                  <input
                    ref={uploadImageFieldRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    title="image upload"
                    onChange={handleImageUpload}
                    hidden
                  />

                  <div>
                    <Form.Label> Select image to upload files</Form.Label>{" "}
                    <br />
                    <Form.Label className="text-muted">
                      File should not exceed 1mb
                    </Form.Label>
                  </div>
                </Stack>
              </div>
            </Form.Group>
          ) : (
            <Form.Group className="mb-3" controlId="imageUrl">
              <Form.Label>Change the image:</Form.Label>
              <br />
              <input
                ref={uploadImageFieldRef}
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                title="image upload"
                onChange={handleImageUpload}
                hidden
              />

              <Image
                onClick={() => uploadImageFieldRef.current!.click()}
                style={{
                  height: "150px",
                  width: "150px",
                }}
                fluid={true}
                src={vendorInfoFormik.values.imageUrl}
              />
              <br />
            </Form.Group>
          )}

          {/* <Form.Group className="mb-3" controlId="description">
            <Form.Label>Select your location:</Form.Label>
            <br />
            <Image
              style={
                {
                  // height: "150px",
                  // width: "150px",
                }
              }
              fluid={true}
              src="./map.png"
            />
            <br />
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Stack className="w-100" direction="horizontal" gap={3}>
            <Button
              variant="outline-secondary"
              onClick={() => setShowVendorInfoModal(false)}
              className="ms-auto"
            >
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              // disabled={isUploadingImage || isUploadingInfo}
            >
              Save changes
              {/* {isUploadingImage || isUploadingInfo
                ? "Loading..."
                : "Save changes"} */}
            </Button>
          </Stack>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default VendorInfoModal;
