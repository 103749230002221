import React from "react";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import {
  Navbar,
  Container,
  Popover,
  OverlayTrigger,
  Card,
} from "react-bootstrap";
import Image from "react-bootstrap/Image";

const Footer = () => {
  let navigation = useNavigate();

  return (
    <Container>
      <footer>
        <a href="#">Policy</a>
        <a href="#">Policy</a>
        <a href="#">Policy</a>
        <a href="#">Policy</a>
        <a href="#">Policy</a>
      </footer>
    </Container>
  );
};

export default Footer;
