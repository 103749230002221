import React, { useState, useRef, useEffect } from "react";

import {
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
  Stack,
  Image,
} from "react-bootstrap";

import { DownloadIcon } from "@radix-ui/react-icons";

import { useFormik } from "formik";

import { signOut } from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";

import {
  firebaseAuth,
  database,
  firebaseStorage,
} from "./../config/firebaseConfig";
import { DealInfo, ImageUploadStatus } from "../types";

import ImageUploadIcon from "./image-upload-icon";
import { Link } from "react-router-dom";

import { qrInfo } from "../atoms/index";
import { useSetRecoilState } from "recoil";

// Component to upload the Deal information

const DealModal = ({
  selectedDeal,
  handleDealModalClose,
  handleDeleteDeal,
}: {
  selectedDeal: DealInfo | null;
  handleDealModalClose: any;
  handleDeleteDeal: any;
}) => {
  const [fileUploadState, setFileUploadState] = useState<ImageUploadStatus>({
    accepted: false,
    rejected: false,
    imageUrl: "",
  });

  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
  const [isUploadingInfo, setIsUploadingInfo] = useState<boolean>(false);
  const setQrInfo = useSetRecoilState(qrInfo);

  const uploadImageFieldRef = useRef<HTMLInputElement>(null);

  const initialDealFormValues: DealInfo = selectedDeal
    ? selectedDeal
    : {
        dealId: "",
        name: "",
        description: "",
        offerType: "FLAT",
        offerValue: 0,
        isActive: true,
        startDate: "",
        endDate: "",
        imageUrl: "",
        createdDate: 0,
        modifiedDate: 0,
        vendorId: "",
      };

  const qrCodeObj = {
    title: "ISC_QR",
    vendorId: initialDealFormValues.vendorId,
    dealId: initialDealFormValues.dealId,
  };

  let cipherQrText = JSON.stringify(qrCodeObj);

  //   const { authUser, loading, clear } = useAuth();

  const dbInstance = collection(database, "Deals");

  const dealFormik = useFormik<DealInfo>({
    initialValues: initialDealFormValues,

    onSubmit: async (dealInfo: DealInfo) => {
      console.log("dealInfo" + JSON.stringify(dealInfo));
      if (dealInfo.startDate >= dealInfo.endDate) {
        alert("Start date cannot be greater or equal to End date");
        return;
      }

      setIsUploadingInfo(true);
      try {
        // throw new Error("Error");
        dealInfo = { ...initialDealFormValues, ...dealInfo };

        dealInfo.vendorId = firebaseAuth.currentUser!.uid;

        let currentTimeStamp = Date.now();

        if (dealInfo.dealId === "") {
          // Add new deal

          dealInfo.createdDate = currentTimeStamp;
          const newDeal = await addDoc(dbInstance, dealInfo);
          console.log("New deal added: ", newDeal);
        } else {
          // Update existing deal
          console.log(dealInfo.dealId);
          dealInfo.modifiedDate = currentTimeStamp;
          const updatedInfo = doc(database, "Deals", dealInfo.dealId);
          const updateDeal = await updateDoc(updatedInfo, dealInfo);
          console.log("Deal updated: ");
        }

        handleDealModalClose();

        // setDealDataCreatedUpdated(true);
        // setOpenDealModal(false);
      } catch (e) {
        alert("Deal could not be update. Please retry.");
      } finally {
        setIsUploadingInfo(true);
      }

      //   alert(values)
      //   Router.push("/dashboard");
    },
  });

  const handleImageUpload = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const image: File = (target.files as FileList)[0];

    // Rejecting image if size is grater than 1 MB.
    if (image.size > 1 * 1024 ** 2) {
      // Greater than 1 mb
      alert("Image size too big");
      setFileUploadState((prevState) => ({ ...prevState, rejected: true }));
      return;
    }

    let imageUrl = URL.createObjectURL(image);

    let extension: string = image.name.split(".").pop() || "";
    let fileName: string = `deals_${Date.now()}.${extension}`;

    const storageRef = ref(firebaseStorage, fileName);
    setIsUploadingImage(true);
    uploadBytes(storageRef, image).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(storageRef)
        .then((url) => {
          setIsUploadingImage(false);
          setFileUploadState((prevState) => ({
            ...prevState,
            accepted: true,
            imageUrl: imageUrl,
          }));
          //IMPORTANT  check this
          dealFormik.values.imageUrl = url;
        })
        .catch((error) => {
          alert("Upload failed");
        });
    });
  };

  useEffect(() => {
    let qrObj = {
      ...dealFormik.values,
      ...{ qrCode: JSON.stringify(qrCodeObj) },
    };
    setQrInfo(qrObj);
  }, []);

  return (
    <Modal
      show={true}
      onHide={handleDealModalClose}
      backdrop="static"
      keyboard={false}
    >
      <Form onSubmit={dealFormik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Enter your deal!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              required
              onChange={dealFormik.handleChange}
              value={dealFormik.values.name}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={dealFormik.handleChange}
              value={dealFormik.values.description}
            />
          </Form.Group>

          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="offerType">
                  <Form.Label>Offer type:</Form.Label>
                  <Form.Check
                    type="radio"
                    name="offerType"
                    label="Flat Discount"
                    value="FLAT"
                    defaultChecked={dealFormik.values.offerType === "FLAT"}
                    onChange={dealFormik.handleChange}
                  />
                  <Form.Check
                    type="radio"
                    name="offerType"
                    label="Percentage"
                    value="PERCENT"
                    defaultChecked={dealFormik.values.offerType === "PERCENT"}
                    onChange={dealFormik.handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="offerValue">
                  <Form.Label>Offer value:</Form.Label>
                  <Form.Control
                    type="number"
                    onChange={dealFormik.handleChange}
                    value={dealFormik.values.offerValue}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>

          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label>Start Date:</Form.Label>
            <Form.Control
              type="date"
              // label=""
              // defaultChecked={dealFormik.values.isActive}
              value={dealFormik.values.startDate}
              onChange={dealFormik.handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="endDate">
            <Form.Label>End Date:</Form.Label>
            <Form.Control
              type="date"
              // label=""
              // defaultChecked={dealFormik.values.isActive}
              value={dealFormik.values.endDate}
              onChange={dealFormik.handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="isActive">
            <Form.Label>Is Active:</Form.Label>
            <Form.Check
              type="switch"
              label=""
              // defaultChecked={dealFormik.values.isActive}
              checked={dealFormik.values.isActive}
              onChange={dealFormik.handleChange}
            />
          </Form.Group>

          {dealFormik.values.imageUrl === "" ? (
            <Form.Group className="mb-3" controlId="imageUpload">
              {/* For uploading the image */}
              <div onClick={() => uploadImageFieldRef.current!.click()}>
                <Stack direction="horizontal" gap={3}>
                  <ImageUploadIcon
                    status={fileUploadState}
                    style={{
                      width: 80,
                      height: 80,
                      // color: getIconColor(status, theme),
                    }}
                  />
                  <input
                    ref={uploadImageFieldRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    title="image upload"
                    onChange={handleImageUpload}
                    hidden
                  />

                  <div>
                    <Form.Label> Select image to upload files</Form.Label>{" "}
                    <br />
                    <Form.Label className="text-muted">
                      File should not exceed 1mb
                    </Form.Label>
                  </div>
                </Stack>
              </div>
            </Form.Group>
          ) : (
            <Form.Group className="mb-3" controlId="generateQR">
              <Image
                style={{
                  height: "150px",
                  width: "150px",
                }}
                fluid={true}
                src={dealFormik.values.imageUrl!}
              />
              <br />

              <Link
                to="/generateQr"
                target="_blank"
                className="btn btn-primary mb-2"
              >
                <DownloadIcon /> Download QR
              </Link>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Stack className="w-100" direction="horizontal" gap={3}>
            {dealFormik.values.dealId !== "" && (
              <Button variant="danger" onClick={handleDeleteDeal}>
                Delete
              </Button>
            )}

            <Button
              variant="outline-secondary"
              onClick={handleDealModalClose}
              className="ms-auto"
            >
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={isUploadingImage || isUploadingInfo}
            >
              {isUploadingImage || isUploadingInfo
                ? "Loading..."
                : "Save changes"}
            </Button>
          </Stack>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DealModal;
