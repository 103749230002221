import { atom } from "recoil";
import { DealInfo } from "../types";
import localStorageEffect from "./util";

const dealAtom = atom({
  key: "DealAtom",
  default: [] as DealInfo[],
  effects: [localStorageEffect("deal_info")],
});

const selectedDealAtom = atom({
  key: "SelectedDealAtom",
  default: {} as DealInfo | null,
  effects: [localStorageEffect("selected_deal_info")],
});

const dealInfoModalAtom = atom<any>({
  key: "DealInfoModalAtom",
  default: false,
});

export { dealAtom, dealInfoModalAtom, selectedDealAtom };
